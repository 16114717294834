import React from 'react';
import {graphql, Link} from 'gatsby';

import Layout from '../components/layout';
import PageHero from "../components/pageHero/PageHero";
import Section from '../components/section/Section';
import PostCard from '../components/postCard/PostCard';
import House from "../components/house/House";
import Slider from "react-slick";

const postArchiveTemplate = ({
         data: {
             allWordpressPost: {
                 edges: postData
             },
             allWordpressWpHouses: {
                 edges: houses
             },
             file
         },
         pageContext: {
             humanPageNumber,
             numberOfPages
         },
}) => {
    const settings = {
        centerMode: true,
        centerPadding: 0,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        focusOnSelect: true,
        waitForAnimate: false,
        cssEase: 'ease-out',
        arrows: true
    };

    return (
        <Layout>
            <PageHero header="Aktualności" className="pb-20" background={file.publicURL}>
            </PageHero>
            <Section header="Lista aktualności" hideHeader className="section-dark">
                <div className="row align-items-stretch pt-3 pt-lg-7 pb-3 pb-lg-8">
                    {postData.map((post, i) => (
                        <PostCard key={i} post={post} />
                    ))}
                </div>
            </Section>
            <Section header="Poznaj nasze domy">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8 text-center d-flex align-items-center flex-column d-none d-lg-block">
                        <div className="d-flex flex-row justify-content-center">
                            {houses.map((houseItem, i) => {
                                return (
                                    <Link key={i} className="text-decoration-none d-flex flex-row justify-content-center" to={'/budynki/' + houseItem.node.slug}>
                                        <House className="m-2" key={i} houseData={houseItem} />
                                    </Link>
                                )
                            })}
                        </div>
                        <p className="text-center mt-4 mb-5 h4 fw-light">
                            Mamy w ofercie większą chatę, a w planach kolejne otwarcia nowych mniejszych domków. Aby dowiedzieć się więcej i być na czasie kliknij poniżej.
                        </p>
                        <Link to='/znizki-ciekawostki-terminy/' className="btn btn-outline-primary mb-8 fw-bold">Chcę więcej info</Link>
                    </div>

                    <div className="col col-lg-8 text-center d-flex align-items-center flex-column d-block d-lg-none">
                        <div className="w-75 d-flex flex-column align-content-center justify-content-center mb-6">
                            <Slider {...settings}>
                                {houses.map((houseItem, i) => {
                                    return (
                                        <Link key={i} className="text-decoration-none d-flex justify-content-center" to={'/budynki/' + houseItem.node.slug}>
                                            <House className="m-2" key={i} houseData={houseItem} />
                                        </Link>
                                    )
                                })}
                            </Slider>
                        </div>
                        <p className="text-center mt-4 mb-5 h4 fw-light">
                            Mamy w ofercie większą chatę, a w planach kolejne otwarcia nowych mniejszych domków. Aby dowiedzieć się więcej i być na czasie kliknij poniżej.
                        </p>
                        <Link to='/znizki-ciekawostki-terminy/' className="btn btn-outline-primary mb-8 fw-bold">Chcę więcej info</Link>
                    </div>
                </div>
            </Section>
        </Layout>
    )
}

export default postArchiveTemplate;

export const postData = graphql`
    query postDataQuery($skip: Int!, $limit: Int!) {
      allWordpressPost(skip: $skip, limit: $limit) {
        edges {
          node {
            title
            link
            content
            featured_media {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 4000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      allWordpressWpHouses(sort: {fields: wordpress_id, order: ASC}) {
        edges {
          node {
            acf {
              people
              space
              icon {
                localFile {
                  publicURL
                }
              }
            }
            slug
            title
            wordpress_id
          }
        }
      }
      file(relativePath: {eq: "AdobeStock_257047241.png"}) {
        publicURL
      }
    }
`;